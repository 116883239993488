import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Title, Twitter, Testonimal } from "../../components/Globals";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { FaAngleDoubleDown } from "react-icons/fa";
const DataAnalyticsPage = () => {
  const ref = React.createRef();

  const arrowClick = () =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    const [services] = React.useState([
        {
          id: 1,
          title: 'Tailored Solutions',
          desc: 'Customized analytics solutions to match your unique business needs.',
          image: 'Dataanalytics.jpeg',
          alt:'Tailored Data Analytics Services',
          imagetitle:'Tailored data analytics services',
        },
        {
          id: 2,
          title: 'Personalized Collaboration',
          desc: 'Close collaboration to understand your objectives and challenges.',
          image: 'Datavisualization.jpeg',
          alt:'personalized Data analytics solutions ',
          imagetitle:'Personalized collaboration for data analytics solutions',
        },
        {
          id: 3,
          title: 'Max Data Value',
          desc: 'Solutions that address specific requirements, delivering maximum data value. ',
          image: 'DataScience.jpeg',
          alt:'data and analytics services to maximize returns',
          imagetitle:'Max Data Value',
          
        },
        {
          id: 4,
          title: 'Seasoned Expertise',
          desc: 'Deep industry knowledge with technological excellence. ',
          image: 'Datavisualization.jpeg',
          alt:'expert data analytics company india usa',
          imagetitle:'Technical Excellence',
        },
      ]);



    return (
          <Layout>
                <Helmet>
        <title>Data Analytics Consulting Solutions And Services | Cymetrix Software</title>
        <meta name="description" content="Cymetrix Software offers data analytics services to help businesses unlock powerful insights from data and maximize the use of tech and AI investments."/>
        <meta name="keywords" content="cymetrix,data analytics, data analytics consulting, data and cloud services, data analytics company, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/data-analytics/" />
      </Helmet>
              <div className="data-analysis-background flex flex-col">

              <h1 className=" xl:text-4xl xl:m-56  md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
                 STRATEGY TRANSFORMATION USING DATA. RIGHT NOW.

                    </h1>
                <button class="focus:outline-none " >
         <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
        </button>
              </div>
              <div className="relative max-w-full mx-auto" style={{backgroundColor: '#f6f6f6'}} ref={ref}>
                  <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 justify-center">
                      <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                          <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                              <img
                                  className="h-64 w-64"
                                  src={require('./../../images/services/saleforceApiImg.png')}
                                  alt="Hero"
                                  />
                          </div>
                      </div>
                      <div className="sma:mx-3 relative lg:w-8/12 flex-shrink-0 lg:text-lef mx-auto justify-center">
                          <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                          Data and Analytics Consulting
                          </h1><br />
                          <hr className="border-solid border-black mb-5" style={{borderWidth: 1}}/>
                          <p className="font-hairline text-justify">Cymetrix leads the way in Data Analytics Services, leveraging over three decades of expertise to provide businesses with comprehensive solutions. We build infrastructure for data aggregation, analysis, and reporting ensures even the most voluminous and high-velocity data is organized seamlessly. Through a commitment to time-saving automation, easy-to-read reports, trustworthy data, and value-focused analytics, we empower organizations to make informed decisions and plan future actions based on historical insights.
                         
                          </p><br/>
                          

                      </div>
                  </div>
  
                  
              </div>
  
        <div className="flex flex-col mx-auto h-70 max-w-6xl my-5">
        <Title title="PLATFORMS / TOOLS"/>
        <div className="relative max-w-6xl mx-auto"></div>
        <div> <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                          Databases
                          </h1></div>
        <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 my-2 mb-8" />
        <AwesomeSlider bullets={false} className="bg-white mb-8  " >
        <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
        <div class="px-2">
        <div class="flex -mx-2">
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/mysql.jpg')} title="MySQl Database Services" alt="MySQL Relational Database Management System Services provided by Cymetrix Software" className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
        <div class="h-64">
        <img src={require('./../../images/platformtools/mongodb11.jpg')} title="Leading NoSQL database MongoDB"  alt="MongoDB database | Cymetrix Software" className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/oraclerdbms.png')} title="Oracle Database Services" alt="Oracle Database used by Cymetrix Software" className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/ibmdb22.jpg')} title="IBM DB2 Database " alt=" IBM DB2 database services used by Cymterix Software"  className="w-64 h-40"/>
      </div>
    </div>
  </div>
</div>
 </div>
  <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
  <div class="px-2">
  <div class="flex -mx-2">
    <div class="w-1/4 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/microsoft-sql-server-logo.png')} className="w-64 h-40"/>
      </div>
    </div>
    <div class="w-1/4 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/Amazon-RDS.png')} className="w-64 h-40"/>
      </div>
    </div>
    <div class="w-1/4 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/postgre.png')} className="w-64 h-40"/>
      </div>
    </div>
  </div>
</div>
</div>
</AwesomeSlider>
</div>

<div className="flex flex-col mx-auto h-70 max-w-6xl my-5 content-center">
<div> <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                          Data Warehouse
                          </h1></div>
        <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 my-2 mb-8" />
        <AwesomeSlider bullets={false} className="bg-white mb-8  " >
        <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
        <div class="px-2">
        <div class="flex -mx-2">
        <div class="w-1/3 px-2 ">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/snowflake.jpeg')} title="Snowflake - Cloud based data warehousing company " alt="Snowflake data warehousing platform used by cymterix Software" className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/3 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/AmazonRedshift.png')} className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/3 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/bigquery.webp')} title="Bigquery Cloud Data Warehouse platform" alt="BigQuery serverless Datawarehouse platform services offered by Cymetrix Software " className="w-64 h-40"/>
        </div>
        </div>
    
  </div>
</div>
 </div>

</AwesomeSlider>
</div>

<div className="flex flex-col mx-auto h-70 max-w-6xl my-5">
<div> <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                          ETL
                          </h1></div>
        <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 my-2 mb-8" />
        <AwesomeSlider bullets={false} className="bg-white mb-8  " >
        <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
        <div class="px-2">
        <div class="flex -mx-2">
        <div class="w-1/3 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/talend.png')} title="Talend - ETL Tool for Data Integration" alt="ETL tool used for Data Integration and Big data | Cymetrix Software" className="w-64 h-40"/>
        </div>
        </div>
        {/* <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/ibmdatastage.jpg')} className="w-64 h-40"/>
        </div>
        </div> */}
        <div class="w-1/3 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/stitch1.png')} title="Stitch - A cloud ETL Service" alt="Stitch - A Talend Company working together as a cloud ETL service  - Cymetrix Software " className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/3 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/fivetran1.png')} title="Fivetran ETL platform tool" alt="Fivetran is a cloud-based ETL platform - Cymetrix Software" className="w-64 h-40"/>
      </div>
    </div>
  </div>
</div>
 </div>
  <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
  <div class="px-2">
  <div class="flex -mx-2">
    <div class="w-1/3 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/keebola.png')} className="w-64 h-40"/>
      </div>
    </div>
    <div class="w-1/3 px-2">
      <div class="h-64">
      <img src={require('./../../images/platformtools/pentaho.jpg')} className="w-64 h-40"/>
      </div>
    </div>
    
  </div>
</div>
</div>
</AwesomeSlider>
</div>






<div className="flex flex-col mx-auto h-70 max-w-6xl my-5">
<div> <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                          Cloud
                          </h1></div>
        <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 my-2 mb-8" />
        <AwesomeSlider bullets={false} className="bg-white mb-8  " >
        <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
        <div class="px-2">
        <div class="flex -mx-2">
        <div class="w-1/3 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/aws.png')} title="Amazon Web Services - A cloud computing Service" alt="Amazon Web Services - A cloud computing platform service  by Cymetrix Software " className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/3 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/google-cloud1.png')} title="Google Cloud Services" alt="Google cloud computing services  offered by Cymetrix Software" className="w-64 h-40"/>

        </div>
        </div>
        <div class="w-1/3 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/azure.png')} title=" Azure - Cloud computing Services" alt="Azure public cloud computing platform services by Cymetrix Software" className="w-64 h-40"/>
        </div>
        </div>
  </div>
</div>
 </div>
  
</AwesomeSlider>
</div>

<div className="flex flex-col mx-auto h-70 max-w-6xl my-5">
<div><h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
Big Data</h1></div>
        <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 my-2 mb-8" />
        <AwesomeSlider bullets={false} className="bg-white mb-8  " >
        <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
        <div class="px-2">
        <div class="flex -mx-2">
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/hadoop.jpg')}title="Apache Hadoop - An open-source software framework services" alt="Cymetrix Software offers Hadoop framework for processing Bigdata"className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/hive.png')} title="Hive Bigdata platform/tools" alt="Hive big data platform offered by Cymetrix Software"className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/spark.jpg')} title="Apache Spark - A bigdata processing tool" alt="Cymetrix Software offers Apache Spark Analytics Engine for Bigdata" className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
      <div class="h-64">
      <img src={require('./../../images/platformtools/mongodb11.jpg')} title="Leading NoSQL database MongoDB"  alt="MongoDB database | Cymetrix Software" className="w-64 h-40"/>
      </div>
    </div>
  </div>
</div>
 </div>
 
</AwesomeSlider>
</div>

<div className="flex flex-col mx-auto h-70 max-w-6xl my-5">
<div> <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
Data Visualization (BI Tools)</h1></div>
        <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 my-2 mb-8" />
        <AwesomeSlider bullets={false} className="bg-white mb-8  " >
        <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
        <div class="px-2">
        <div class="flex -mx-2">
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/looker.webp')} className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/tableau.png')} className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
        <div class=" h-64">
        <img src={require('./../../images/platformtools/google-data-studio.jpeg')} className="w-64 h-40"/>
        </div>
        </div>
        <div class="w-1/4 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/powerbi.jpg')} title="Power BI - Business Analytical Tool" alt="Business Visualization tool  PowerBI used by Cymetrix Software" className="w-64 h-40"/>
      </div>
    </div>
  </div>
</div>
 </div>
  <div className="flex flex-col mx-24 text-black " class="bg-white text-black">
  <div class="px-2">
  <div class="flex -mx-2">
    <div class="w-1/4 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/klipfolio1.png')} className="w-64 h-40"/>
      </div>
    </div>
    <div class="w-1/4 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/qlikview.webp')} className="w-64 h-40"/>
      </div>
    </div>
    <div class="w-1/4 px-2">
      <div class="h-64">
      <img src={require('./../../images/platformtools/domo.png')} className="w-64 h-40"/>
      </div>
      
    </div>
    <div class="w-1/4 px-2">
      <div class="h-64">
      <img src={require('./../../images/platformtools/slemma.webp')} className="w-64 h-40"/>
      </div>
      
    </div>
  </div>
</div>

</div>

<div className="flex flex-col mx-24 text-black " class="bg-white text-black">
  <div class="px-2">
  <div class="flex -mx-2">
    <div class="w-1/3 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/QlikSense.jpg')} className="w-64 h-40"/>
      </div>
    </div>
    <div class="w-1/3 px-2">
      <div class=" h-64">
      <img src={require('./../../images/platformtools/grow.webp')} className="w-64 h-40"/>
      </div>
    </div>
    
  </div>
</div>

</div>
</AwesomeSlider>
</div>



<div className="product-background w-full flex flex-col items-center" title="">
      <div className="max-w-6xl mx-4 my-16">
          <h1 className="text-white text-3xl text-left sma-xl font-thin">OUR DATA AND ANALYTICS CONSULTING SERVICES</h1>
          <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
          <div class="mt-4 md:w-1/2 lg:w-1/2">
                  <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                      <div className="text-white text-center text-lg">Data Warehousing</div>
                      <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                      <div class="mt-2 text-base text-gray-300 text-center">Experience a cutting-edge approach to data collection and insights with our Data Warehousing service. We optimize data, ensuring blazing-fast speed and efficiency, even with massive data volumes. Our expert implementation ensures peak performance and maximizes the utility of your data.</div>
                  </div>
              </div>

              <div class="mt-4 md:w-1/2 lg:w-1/2">
                  <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                      <div className="text-white text-center text-lg">Data Migration</div>
                      <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                      <div class="mt-2 text-base text-gray-300 text-center">Our Data Migration service empowers you to upgrade and enhance your data through digital transformation. Our automated process ensures you reap the full benefits hassle-free. With the advantage of non-disruptive operation, high security, scalability, and reliability, we help you oversee maintenance and ensure a smooth transition.</div>
                  </div>
              </div>

              <div class="mt-4 md:w-1/2 lg:w-1/2">
                  <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                      <div className="text-white text-center text-lg">Big Data</div>
                      <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                      <div class="mt-2 text-base text-gray-300 text-center">Our Big Data solutions not only mitigate risks but also provide deep insights into operational processes, customer behaviour, and more. With cutting-edge tools and the latest technology, we make your desired business outcomes attainable. Our expertise lies in organizing large datasets into a unified, invaluable resource.</div>
                  </div>
              </div>

              <div class="mt-4 md:w-1/2 lg:w-1/2">
                  <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                      <div className="text-white text-center text-lg">Data Quality Management</div>
                      <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                      <div class="mt-2 text-base text-gray-300 text-center">Our Data Quality Management service serves as the sentinel of operational accuracy. Think of it as 'rescue management' that steps in when challenges arise. Our expert team handles extensive quality control, migration, integration, data governance, deployment, and archiving with precision. Trust us to preserve your data, enhance security, and deliver immaculate, tailored data for your review.</div>
                  </div>
              </div>

              <div class="mt-4 md:w-1/2 lg:w-1/2">
                  <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                      <div className="text-white text-center text-lg">Data Science</div>
                      <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                      <div class="mt-2 text-base text-gray-300 text-center">Data Science, a cornerstone of Data Analytics, finds its pinnacle at Company ABC. We possess the mastery to wrangle and unify massive data quickly. Our expertise spans Data Visualization, mathematics, advanced statistics, Machine Learning, programming, predictive modelling, and beyond, ensuring a comprehensive approach to your data needs.</div>
                  </div>
              </div>


              <div class="mt-4 md:w-1/2 lg:w-1/2">
                  <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                      <div className="text-white text-center text-lg">Cloud</div>
                      <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                      <div class="mt-2 text-base text-gray-300 text-center">Cymetrix's Cloud Computing Services enables our clients to explore and evaluate the opportunities the Cloud offers and simply transition their business by maximizing the benefits. We offer a strategic assessment of your business needs and a customized implementation plan to facilitate the transition to a Cloud-based model.</div>
                  </div>
              </div>
          </div>
          <div className="flex flex-row justify-center">
              <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                  <Link target="_blank"
                      to="/contact-us">Contact Cymetrix</Link>
              </div>
          </div>
      </div>
    </div>


        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>Driving growth in action with data-driven insights
          </span>
        </div>
        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">1. Understand the objective</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">We start by gaining a deep understanding of your specific data analytics goals and business objectives.
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">2. Import the data</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Next, we collect and import the relevant data from various sources, ensuring comprehensive coverage.
                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">3. Explore and clean the data</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">We meticulously explore and clean the data to ensure accuracy and reliability, identifying patterns and outliers.
                  
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">4. Model the data</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Using advanced analytics techniques, we model the data to derive valuable insights, uncover trends, and make data-driven predictions.
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">5. Communicate the results</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Finally, we present the results in a clear and actionable manner, empowering you to make informed decisions and drive business success through data-driven strategies.
                  
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div className="service-background w-full flex flex-col items-center">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Why Partner With Cymetrix For Data Analytics Implementation Journey?" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                              <img src={require(`../../images/services/${service.image}`)} title={services.imagetitle} alt={services.alt} className="w-20 h-20" alt=""/>
                              </div>
                              <h1 className="mt-5 text-center">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                              
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
          
        </div>

       
        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/business-analytics-companies-india-usa.png')} alt="Business Analytics services company India" title="Business analytics trends" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/04/18/how-business-analytics-trends-are-transforming-industries/" >How Business Analytics Trends Are Transforming Industries</Link></h1>
                            <p className="text-lg">Explore how these trends pose challenges to companies looking to stay ahead of the curve.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/data-marketing-analytics-companies-usa.png')} alt="Marketing analytics implementation services usa| Cymetrix Software" title="Marketing analytics tools" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/03/29/tools-for-marketing-analytics-leverage-data-driven-insights/">Tools For Marketing Analytics</Link></h1>
                            <p className="text-lg">Read about how to leverage data-driven insights for marketing</p>
                        </div>
                    </div>
                </div>
            </div>


          </Layout>
      );
  }
  
  export default DataAnalyticsPage;